<template>
    <div>
        <NavbarCleaning :translator="translator" :user="user" />

        <div class="container mt-5">
            <!-- Cabecera -->
            <header v-if="translator" class="p-3">
                <div class="flex-titular">
                    <div>
                        <h1>{{ translator.neteja }} <span class="ml-2 titular-fecha" v-if="!isPreLoad" v-html="TransformElegantDate(cleaningDetail.cleaning.cleaning_date)"></span></h1>
                    </div>
                    <div class="text-right">
                        <router-link class="link-atras" :to="{ name: setRoute, query: { id: $route.query.id }}">
                            <span style="font-weight:300;"><i class="fal fa-chevron-left"></i> {{ translator.tornar }}</span>
                        </router-link>

                    </div>
                </div>
            </header>
            <!-- Fin cabecera -->

            <div class="pc">
                <!-- Spiner -->
                <div v-if="isPreLoad" style="height: 90vh; display:flex; justify-content:center; align-items:center; background-color: #f6f7f9;">
                    <div class="hollow-dots-spinner">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>
                </div>
                <!-- Fin spiner -->
                

                <div v-if="!isPreLoad"  class="container p-4">


                    <!-- Detalles de la limpieza -->
                    <div class="row pt-4" 
                        v-if="cleaningDetail.cleaning.cleaning_person != '' ||
                            cleaningDetail.cleaning.cleaning_remarks != '' ||
                            cleaningDetail.cleaning.cleaning_status != '' ||
                            cleaningDetail.cleaning.cleaning_time != ''
                    ">

                        <!-- titulo sección -->
                        <div class="col-7 col-md-9">
                            <h5>
                                <i class="fal fa-file-alt pr-1"></i>
                                <span>{{ translator.detalls }}</span>
                            </h5>                
                        </div>

                        <!-- Botón estado limpieza -->
                        <div class="col-5 col-md-3">
                            <button v-if="cleaningDetail.cleaning.cleaning_status === 'pending'" @click="cambiaEstado(false)" class="btn btn-primary">
                                <span v-if="user.user_language == '2'">Start</span>
                                <span v-else>{{ translator.inici }}</span>
                                <svg v-if="loadingStateToInitial" width="16" height="16" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#000" class="ml-2">
                                    <g fill="none" fill-rule="evenodd">
                                        <g transform="translate(1 1)" stroke-width="2">
                                            <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                                <animateTransform
                                                    attributeName="transform"
                                                    type="rotate"
                                                    from="0 18 18"
                                                    to="360 18 18"
                                                    dur="1s"
                                                    repeatCount="indefinite"/>
                                            </path>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                            <button v-else-if="cleaningDetail.cleaning.cleaning_status === 'in_progress'" @click="cambiaEstado(true)" class="btn btn-primary bg-verde">
                                {{ translator.finalitzar }}
                                <svg v-if="loadingStateToFinish" width="16" height="16" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#000" class="ml-2">
                                    <g fill="none" fill-rule="evenodd">
                                        <g transform="translate(1 1)" stroke-width="2">
                                            <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                                <animateTransform
                                                    attributeName="transform"
                                                    type="rotate"
                                                    from="0 18 18"
                                                    to="360 18 18"
                                                    dur="1s"
                                                    repeatCount="indefinite"/>
                                            </path>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>    

                        <div class="col mt-4">
                            <article class="card p-4">

                                <div v-if="cleaningDetail.property.lodging_name != ''" class="titular-card">
                                    <span>{{ translator.propietat }}:</span>
                                    <span><a :href="`https://www.google.com/maps/search/?api=1&query=${cleaningDetail.property.lodging_latitude},${cleaningDetail.property.lodging_longitude}`" target="_blank"><i class="fal fa-map-marker-alt"></i> {{ cleaningDetail.property.lodging_name }}</a></span>
                                </div>
                                <div v-if="cleaningDetail.cleaning.cleaning_person != ''">
                                    <span>{{ translator.persona }}:</span>
                                    <span>{{ cleaningDetail.cleaning.cleaning_person }}</span>
                                </div>
                                <div v-if="cleaningDetail.cleaning.cleaning_status != ''">
                                    <span>{{ translator.estat }}:</span>
                                    <span v-if="cleaningDetail.cleaning.cleaning_status == 'pending'">{{ translator.pendent }}</span>
                                    <span v-else-if="cleaningDetail.cleaning.cleaning_status == 'in_progress'">{{ translator.enproces }}</span>
                                    <span v-else>{{ translator.acabat }}</span>
                                </div>
                                <div v-if="cleaningDetail.cleaning.cleaning_time != ''">
                                    <span>{{ translator.hora }}:</span>
                                    <span>{{ cleaningDetail.cleaning.cleaning_time }}h.</span>
                                </div>
                                <div v-if="cleaningDetail.cleaning.cleaning_remarks != ''">
                                    <span>{{ translator.observacionsreserva }}:</span>
                                    <span>{{ cleaningDetail.cleaning.cleaning_remarks }}</span>
                                    
                                </div>
                                <div v-if="cleaningDetail.cleaning.cleaning_property_remarks != ''" >
                                    <span>{{ translator.observacionspropietari }}:</span>
                                    <div class="align-items-center" v-html="cleaningDetail.cleaning.cleaning_property_remarks"></div>
                                </div>
                                <div class="flex-nowrap" v-if="cleaningDetail.cleaning.cleaning_service_remarks != ''">
                                    <span class="mr-2">{{ translator.avisnetejaanterior }}:</span>
                                    <div class="fondo-oscuro">
                                        <i @click="borrarObservaciones" class="fad fa-times-circle p-2" style="color:red; position: absolute; top: -11px; right: -11px; cursor: pointer;"></i>
                                        <p class="p-2">{{ cleaningDetail.cleaning.cleaning_service_remarks }}</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>  

                    <div v-else class="row pt-4">
                        <!-- titulo sección -->
                        <div class="col-12">
                            <h5>
                                <i class="fal fa-file-alt pr-1"></i>
                                <span>{{ translator.detalls }}</span>
                            </h5>                
                        </div>
                        <div class="col mt-4">
                            <article class="card p-4">
                                <div v-if="cleaningDetail.property.lodging_name != ''" class="">
                                        <span>{{ translator.propietat }}:</span>
                                        <span><a :href="`https://www.google.com/maps/search/?api=1&query=${cleaningDetail.property.lodging_latitude},${cleaningDetail.property.lodging_longitude}`" target="_blank"><i class="fal fa-map-marker-alt"></i> {{ cleaningDetail.property.lodging_name }}</a></span>
                                    </div>
                            </article>
                        </div>
                    </div>

                    
                    <!-- Tareas o instrucciones -->
                    <div class="row pt-5" v-if="cleaningDetail.cleaning.cleaning_instructions && cleaningDetail.cleaning.cleaning_instructions.length > 0">
                
                        <div class="col-12">
                            <h5>
                                <i class="fa-light fa-clipboard-list pr-1"></i>
                                <span v-cloak> {{ translator.tasquesiverificacions }}</span>
                            </h5>                
                        </div>
                    
                        <div class="col-12 py-4">
                            <div class="card py-4">

                                <article v-for="(task, i) in cleaningDetail.cleaning.cleaning_instructions" :key="i" class="card my-2 tareas">

                                    <!-- Lectura -->
                                    <p v-if="task.cleaning_instruction_type == 'remark'" class="d-flex align-items-center tarea remark">
                                        <i class="fa-light fa-eye"></i>
                                        <span class="ml-2" v-html="task.cleaning_instruction_remarks"></span>
                                    </p>
                                    
                                    <!-- Checkbox -->
                                    <p v-if="task.cleaning_instruction_type == 'confirm'" @click="completeTask(task)" class="d-flex align-items-center tarea">

                                        <svg v-if="taskLoading == task.cleaning_instruction_id" width="16" height="16" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#000">
                                            <g fill="none" fill-rule="evenodd">
                                                <g transform="translate(1 1)" stroke-width="2">
                                                    <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                                                    <path d="M36 18c0-9.94-8.06-18-18-18">
                                                        <animateTransform
                                                            attributeName="transform"
                                                            type="rotate"
                                                            from="0 18 18"
                                                            to="360 18 18"
                                                            dur="1s"
                                                            repeatCount="indefinite"/>
                                                    </path>
                                                </g>
                                            </g>
                                        </svg>
                                        <i v-else-if="task.cleaning_instruction_status == 'true'" class="fas fa-check-circle task-confirm"></i>
                                        <i v-else class="fal fa-circle"></i>

                                        <span class="ml-2" :class="task.cleaning_instruction_status == 'true' ? 'text-checked' : ''" v-html="task.cleaning_instruction_remarks"></span>
                                    </p>
                                
                                    <!-- Subir foto -->
                                    <label :for="`image1${task.cleaning_instruction_id}`"  v-if="task.cleaning_instruction_type == 'photo'" class="d-flex justify-content-between align-items-center py-2 tarea" :class="task.cleaning_instruction_status == 'false' ? 'cursor-pointer' : ''">
                                        <image-uploader
                                            :id="`image1${task.cleaning_instruction_id}`"
                                            :maxWidth="512"
                                            :quality="0.9"
                                            :autoRotate="false"
                                            :className="['fileinput']"
                                            class="d-flex justify-content-between task-photo"
                                            outputFormat="verbose"
                                            :preview="false"
                                            :capture="false"
                                            accept="image/*"
                                            doNotResize="['gif', 'svg']"
                                            @onUpload="startImageResize(+`1${task.cleaning_instruction_id}`)"
                                            @input="savePhotoService"
                                            @onComplete="completeTask(task)"
                                            >
                                            <label :for="`image1${task.cleaning_instruction_id}`" slot="upload-label">
                                                <svg v-if="taskLoading == task.cleaning_instruction_id" width="16" height="16" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#000">
                                                    <g fill="none" fill-rule="evenodd">
                                                        <g transform="translate(1 1)" stroke-width="2">
                                                            <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                                                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                                                <animateTransform
                                                                    attributeName="transform"
                                                                    type="rotate"
                                                                    from="0 18 18"
                                                                    to="360 18 18"
                                                                    dur="1s"
                                                                    repeatCount="indefinite"/>
                                                            </path>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <i v-else-if="task.cleaning_instruction_status == 'true'"  class="fas fa-camera task-checked"></i>
                                                <i v-else class="fal fa-camera"></i>
                                                <span class="ml-2" :class="task.cleaning_instruction_status == 'true' ? 'text-checked' : ''">{{ task.cleaning_instruction_remarks }}</span>
                                            </label>
                                        </image-uploader>
                                        <div v-if="task.cleaning_instruction_photo == ''" class="empty-task-photo d-flex justify-content-center align-items-center"> + </div>
                                        <img v-else :src="task.cleaning_instruction_photo" class="show-task-photo">        
                                    </label>
                                
                                </article>
                            </div>
                        </div>
                    </div>  


                    <!-- Detalles de la check-out -->
                    <div class="row pt-5" 
                        v-if="cleaningDetail.departure.departure_reservation != '' ||
                            cleaningDetail.departure.departure_remarks != '' ||
                            cleaningDetail.departure.departure_cleaning_remarks != '' ||
                            cleaningDetail.departure.departure_time != ''
                    ">
                
                        <div class="col-12">
                            <h5>
                                <i class="fal fa-sign-out pr-1"></i>
                                <span v-cloak> {{ translator.checkout }}</span>
                            </h5>                
                        </div>
                    
                        <div class="col mt-4">
                            <article class="card p-4">

                                <div v-if="cleaningDetail.departure.departure_reservation != ''" class="titular-card">
                                    <span>{{ translator.reserva }}:</span>
                                    <span>{{ cleaningDetail.departure.departure_reservation }}</span>
                                </div>
                                <div v-if="cleaningDetail.departure.departure_guests != '0,0,0'">
                                    <span>{{ translator.persones }}:</span>
                                    <span v-html="showPersons(cleaningDetail.departure.departure_guests)"></span>
                                </div>
                                <div v-if="cleaningDetail.departure.departure_nights != '0'">
                                    <span v-if="cleaningDetail.departure.departure_nights == '1'">{{ translator.nit }}</span>
                                    <span v-else>{{ translator.nits }}</span>
                                    <span>{{ cleaningDetail.departure.departure_nights }}</span>
                                </div>
                                <div v-if="cleaningDetail.departure.departure_time != ''">
                                    <span>{{ translator.horasortida }}:</span>
                                    <span>{{ cleaningDetail.departure.departure_time }}h.</span>
                                </div>
                                <div v-if="cleaningDetail.departure.departure_remarks != ''">
                                    <span>{{ translator.observacions }}:</span>
                                    <span>{{ cleaningDetail.departure.departure_remarks }}</span>
                                </div>
                                <div v-if="cleaningDetail.departure.departure_cleaning_remarks != ''">
                                    <span>{{ translator.observacionsneteja }}:</span>
                                    <span>{{ cleaningDetail.departure.departure_cleaning_remarks }}</span>
                                </div>

                            </article>
                        </div>
                    </div>  
                    
                    <!-- Detalles de la check-in -->
                    <div class="row pt-5" 
                        v-if="cleaningDetail.arrival.arrival_cleaning_remarks != '' ||
                            cleaningDetail.arrival.arrival_guests != '0' ||
                            cleaningDetail.arrival.arrival_nights != '0' ||
                            cleaningDetail.arrival.arrival_remarks != '' ||
                            cleaningDetail.arrival.arrival_reservation != '' ||
                            cleaningDetail.arrival.arrival_time != ''
                    ">
                
                        <div class="col-12">
                            <h5>
                                <i class="fal fa-sign-in pr-1"></i>
                                <span v-cloak> {{ translator.checkin }}</span>
                            </h5>                
                        </div>
                    
                        <div class="col mt-4">
                            <article class="card p-4">

                                <div v-if="cleaningDetail.arrival.arrival_reservation != ''" class="titular-card">
                                    <span>{{ translator.reserva }}:</span>
                                    <span>{{ cleaningDetail.arrival.arrival_reservation }}</span>
                                </div>
                                <div v-if="cleaningDetail.arrival.arrival_guests != '0,0,0'">
                                    <span >{{ translator.persones }}:</span>
                                    <span v-html="showPersons(cleaningDetail.arrival.arrival_guests)"></span>
                                </div>
                                <div v-if="cleaningDetail.arrival.arrival_nights != '0'">
                                    <span v-if="cleaningDetail.arrival.arrival_nights == '1'">{{ translator.nit }}</span>
                                    <span v-else>{{ translator.nits }}</span>
                                    <span>{{ cleaningDetail.arrival.arrival_nights }}</span>
                                </div>
                                <div v-if="cleaningDetail.arrival.arrival_time != ''">
                                    <span>{{ translator.horaentrada }}:</span>
                                    <span>{{ cleaningDetail.arrival.arrival_time }}h.</span>
                                </div>
                                <div v-if="cleaningDetail.arrival.arrival_remarks != ''">
                                    <span>{{ translator.observacions }}:</span>
                                    <span>{{ cleaningDetail.arrival.arrival_remarks }}</span>
                                </div>
                                <div v-if="cleaningDetail.arrival.arrival_cleaning_remarks != ''">
                                    <span>{{ translator.observacionsneteja }}:</span>
                                    <span>{{ cleaningDetail.arrival.arrival_cleaning_remarks }}</span>
                                </div>

                            </article>
                        </div>
                    </div>  
                    

                    <!-- Añadir observaciones limpieza -->
                    <div class="row pt-5">
                        <div class="col-12">
                            <h5>
                                <i class="fal fa-vacuum pr-1"></i>
                                <span v-cloak>{{ translator.observacionsneteja }}</span>
                            </h5>               
                        </div>
                        <div class="col mt-4">
                            <div class="card p-4">

                                <div class="form-group">
                                    <label for="formObservaciones" class="mb-2">{{ translator.avisnetejaposterior }}</label>
                                    <textarea v-model="formLimpieza.observaciones" type="text" id="formObservaciones" class="form-control"></textarea>
                                </div>
                                <button class="btn btn-primary mt-3" @click.prevent.self="enviaformObservaciones">
                                    {{ translator.guardar }}
                                </button>
                            </div>
                        </div>
                    </div>
                    

                    <!-- Añadir incidencia -->
                    <div class="row pt-5">
                        <div class="col-12">
                            <h5>
                                <i class="fal fa-tools pr-1"></i>
                                <span v-cloak>{{ translator.afegirincidencia }}</span>
                            </h5>                
                        </div>
                    
                        <div class="col mt-4">
                            <div class="card p-4">
                                
                                <form>
                                    <div class="form-group">
                                        <label for="titular" class="mb-2">{{ translator.titular }}</label>
                                        <input v-model="formIncidencias.titular" type="text" id="titular" class="form-control">
                                    </div>
                                    <div class="form-group">
                                        <label for="descripcion" class="mb-2">{{ translator.descripcio }}</label>
                                        <textarea v-model="formIncidencias.descripcion" type="text" id="descripcion" class="form-control"></textarea>
                                    </div>
                                    <div class="d-flex justify-content-between align-self-center content-inputs flex-wrap">
                                        <div class="form-group align-self-center mb-4">
                                            <div class="form-check d-flex">
                                                <input v-model="formIncidencias.causa" class="form-check-input" type="radio" name="radio-causa" id="desgaste" value="1">
                                                <label class="form-check-label" for="desgaste">{{ translator.desgast }}</label>
                                            </div>
                                            <div class="form-check d-flex">
                                                <input v-model="formIncidencias.causa" class="form-check-input" type="radio" name="radio-causa" id="malUso" value="2">
                                                <label class="form-check-label" for="malUso">{{ translator.malus }}</label>
                                            </div>
                                        </div>

                                        <!-- Bóton guardar fotos -->
                                        <div class="row my-3">
                                            <div class="col">
                                                <div v-if="!hasImage1" class="empty-photo"></div>
                                                <image-uploader
                                                    id="image1"
                                                    :maxWidth="512"
                                                    :quality="0.9"
                                                    :autoRotate="false"
                                                    :className="['fileinput']"
                                                    class="text-center"
                                                    outputFormat="verbose"
                                                    :preview="true"
                                                    :capture="false"
                                                    accept="image/*"
                                                    doNotResize="['gif', 'svg']"
                                                    @onUpload="startImageResize(1)"
                                                    @input="savePhotoService"
                                                    @onComplete="endImageResize('image1')"
                                                >
                                                    <label for="image1" slot="upload-label">
                                                        <span class="btn btn-outline-secondary mt-1 btn-upload-photo" style="width: initial;">
                                                            {{ translator.pujarimatge }}
                                                        </span>
                                                    </label>
                                                </image-uploader>

                                            </div>
                                            <div class="col">
                                                <div v-if="!hasImage2" class="empty-photo"></div>
                                                <image-uploader
                                                    id="image2"
                                                    :maxWidth="512"
                                                    :quality="0.9"
                                                    :autoRotate="false"
                                                    :className="['fileinput']"
                                                    class="text-center"
                                                    outputFormat="verbose"
                                                    :preview="true"
                                                    :capture="false"
                                                    accept="image/*"
                                                    doNotResize="['gif', 'svg']"
                                                    @onUpload="startImageResize(2)"
                                                    @input="savePhotoService"
                                                    @onComplete="endImageResize('image2')"
                                                >
                                                    <label for="image2" slot="upload-label">
                                                        <span class="btn btn-outline-secondary mt-1 btn-upload-photo" style="width: initial;">
                                                            {{ translator.pujarimatge }}
                                                        </span>
                                                    </label>
                                                </image-uploader>

                                            </div>
                                            <div class="col">
                                                <div v-if="!hasImage3" class="empty-photo"></div>
                                                <image-uploader
                                                    id="image3"
                                                    :maxWidth="512"
                                                    :quality="0.9"
                                                    :autoRotate="false"
                                                    :className="['fileinput']"
                                                    class="text-center"
                                                    outputFormat="verbose"
                                                    :preview="true"
                                                    :capture="false"
                                                    accept="image/*"
                                                    doNotResize="['gif', 'svg']"
                                                    @onUpload="startImageResize(3)"
                                                    @input="savePhotoService"
                                                    @onComplete="endImageResize('image3')"
                                                >
                                                    <label for="image3" slot="upload-label">
                                                        <span class="btn btn-outline-secondary mt-1 btn-upload-photo" style="width: initial;">
                                                            {{ translator.pujarimatge }}
                                                        </span>
                                                    </label>
                                                </image-uploader>

                                            </div>
                                        </div>
                                    </div>


                                    <div v-if="formError.length > 0" class="py-3 form-error">
                                        <p v-for="(error, index) in formError" :key="index">{{ error }}</p>
                                    </div>

                                    <button class="btn btn-primary" @click.prevent.self="enviaform">
                                        {{ translator.guardar }}
                                    </button>

                                </form>
                            </div>
                        </div>
                    </div>  

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import NavbarCleaning from "@/components/NavbarCleaning.vue";
    import { mapState } from "vuex";

    export default {
        name: "CleaningDetails",
        components: {
           NavbarCleaning,
        },
        computed: {
            ...mapState("utils", ['user', 'translator']),
            ...mapState("cleaning", ['cleaningDetail', 'numIncidencia']),
            setRoute() {
                const ruta = sessionStorage.getItem("back");
                if ( ruta ) {
                    return ruta;
                } else {
                    return 'Cleaning';
                }
            }
        },
        watch: {
            translator() {
                this.$store.dispatch("cleaning/getCleaningDetail", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), id: this.$route.params.bookingId, data: this.$route.params.cleaningDate });
            },
            cleaningDetail() {
                this.isPreLoad = false;
                this.taskLoading = null;
                this.loadingStateToInitial = false
                this.loadingStateToFinish = false
            },
            numIncidencia() {
                //console.log('pasa');
                this.numIncidencia != '' ? this.serveiEnviarFotos() : '';
            }
        },
        async created() {
            try {
                this.isPreLoad = true;
                document.getElementsByTagName('body')[0].style.backgroundColor = '#f6f7f9';
                this.translator
                    ? await this.$store.dispatch("cleaning/getCleaningDetail", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), id: this.$route.params.bookingId, data: this.$route.params.cleaningDate })
                    : await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
            } catch (error) {
                console.error(error);
            }
        },
        data() {
            return {
                isPreLoad: false,
                formIncidencias: {
                    titular: '',
                    descripcion: '',
                    causa: ''
                },
                formLimpieza: {
                    observaciones: ''
                },
                formError: [],
                servicePhotos: [],
                numPhoto: 1,
                hasImage1: false,
                hasImage2: false,
                hasImage3: false,
                hasImage6: false,
                tasksComples: [],
                taskPhoto: null,
                taskLoading: null,
                loadingStateToInitial: false,
                loadingStateToFinish: false,
            };
        },
        methods: {
            cambiaEstado(estado) {
                estado == true ? this.loadingStateToFinish = true : this.loadingStateToInitial = true;

                let latitud = 0.000000;
                let longitud = 0.000000;
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        latitud = position.coords.latitude;
                        longitud = position.coords.longitude;
                    });
                }

                setTimeout(()=> {
                    this.$store.dispatch("cleaning/postCleaningStatus", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), id: this.$route.params.bookingId, final: estado, fecha: this.$route.params.cleaningDate, lat: latitud, lng: longitud });
                }, 500)
            },
            async enviaform() {
                try {
                    this.formError = [];
                    this.formIncidencias.titular == '' ? this.formError.push(`${this.translator.titular}`) : '';
                    this.formIncidencias.descripcion == '' ? this.formError.push(`${this.translator.descripcio}`) : '';
                    this.formIncidencias.causa == '' ? this.formError.push(`${this.translator.motiuincidencia}`) : '';

                    if (this.formError.length === 0) {
                        await this.$store.dispatch("cleaning/postIncidencia", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), lodging_id: this.cleaningDetail.property.lodging_id, titular: this.formIncidencias.titular, texte: this.formIncidencias.descripcion, motiu: parseInt(this.formIncidencias.causa) });
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            async enviaformObservaciones() {
                try {
                    if (this.formLimpieza.observaciones != '') {
                        await this.$store.dispatch("cleaning/postNetejesObservacions", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), lodging_id: this.cleaningDetail.property.lodging_id, cleaning_service_remarks: this.formLimpieza.observaciones, id: this.$route.params.bookingId, datap: this.$route.params.cleaningDate });
                        this.formLimpieza.observaciones = '';
                        this.$toast.success(this.translator.guardat);
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            async borrarObservaciones() {
                try {
                    
                    this.formLimpieza.observaciones = '';
                    await this.$store.dispatch("cleaning/postNetejesObservacions", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), lodging_id: this.cleaningDetail.property.lodging_id, cleaning_service_remarks: this.formLimpieza.observaciones, id: this.$route.params.bookingId, datap: this.$route.params.cleaningDate });
                  
                } catch (error) {
                    console.error(error);
                }
            },
            savePhotoService(photo) {
                //Listado de tareas
                if (this.numPhoto >= 10) {
                    this.taskPhoto = photo.dataUrl;
                } else {
                    // Incidencia
                    this.image = photo;
                    this.servicePhotos.push({
                            'img': this.numPhoto,
                            'foto': photo.dataUrl
                    });
                }
            },
            startImageResize(numPhoto) {
                this.numPhoto = numPhoto;
            },
            endImageResize(photo) {
                photo === 'image1' ? this.hasImage1 = true: '';
                photo === 'image2' ? this.hasImage2 = true: '';
                photo === 'image3' ? this.hasImage3 = true: '';

            },
            serveiEnviarFotos() {
                try {
                    this.isPreLoad = true;
                    let image1 = "", image2 = "", image3 = "";
                    
                    // Asignación botón con su número de foto
                    for (const value of this.servicePhotos) {
                        if (value.img === 1) {
                            image1 = value.foto;
                        } else if (value.img === 2) {
                            image2 = value.foto;
                        } else if (value.img === 3) {
                            image3 = value.foto;
                        }
                    }

                    // Envia info a la store para hacer el post
                    this.$store.dispatch( "cleaning/postNetejaEnviarFotos", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), id: this.numIncidencia, data: this.$route.params.cleaningDate, image1, image2, image3, idUrl: this.$route.params.bookingId });

                    // Reset y feedback
                    this.servicePhotos = [];
                    this.$toast.success(this.translator.guardat);
                    this.hasImage1 = this.hasImage2 = this.hasImage3 = false;
                    this.formIncidencias.titular = this.formIncidencias.descripcion = this.formIncidencias.causa = '';

                } catch (error) {
                    console.error(error);
                } finally {
                    setTimeout(()=>{
                        this.isPreLoad = false;
                    }, 7000);
                }
            },
            showPersons(data) {
                const persons = data.split(',');
                let adults = '', children = '', babies = '';

                if (persons[0] != '0') {
                    adults = persons[0] == '1' ?  `${persons[0]} ${this.translator.adult}` : `${persons[0]} ${this.translator.adults}`;
                }
                if (persons[1] != '0') {
                    children = persons[1] == '1' ?  `, ${persons[1]} ${this.translator.nen}` : `, ${persons[1]} ${this.translator.nens}`;
                }
                if (persons[2] != '0') {
                    babies = persons[2] == '1' ?  `, ${persons[2]} ${this.translator.nado}` : `, ${persons[2]} ${this.translator.bebes}`;
                }
                return `${adults}${children}${babies}`
            },
            TransformElegantDate(date) {
                if (!date) return ''
                
                const allMonths = JSON.parse(sessionStorage.getItem("diccionario")).monthShort;
                const allWeekdays = JSON.parse(sessionStorage.getItem("diccionario")).weekdayShort;
                const months = [
                    allMonths[0],
                    allMonths[1],
                    allMonths[2],
                    allMonths[3],
                    allMonths[4],
                    allMonths[5],
                    allMonths[6],
                    allMonths[7],
                    allMonths[8],
                    allMonths[9],
                    allMonths[10],
                    allMonths[11],
                ];
                const week = [
                    allWeekdays[0],
                    allWeekdays[1],
                    allWeekdays[2],
                    allWeekdays[3],
                    allWeekdays[4],
                    allWeekdays[5],
                    allWeekdays[6],
                ];

                let long_date = "";
                let day = date.slice(8, 10);
                let month = date.slice(5, 7);
                let year = date.slice(0, 4);

                let new_date = new Date(date);
                let day_week = new_date.getUTCDay();
                long_date = week[day_week] + ", " + day + " " + months[month - 1] + " " + year;

                return long_date;
            },
            completeTask(task) {
                this.taskLoading = null;
                let latitud = 0.000000;
                let longitud = 0.000000;

                this.taskLoading = task.cleaning_instruction_id

                // Tarea es checkbox y no se ha realizado
                if ( task.cleaning_instruction_type == 'confirm') {
                    this.$store.dispatch( "cleaning/postNetejesChecklistConfirmar", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), cleaning_id: this.cleaningDetail.cleaning.cleaning_id, cleaning_instruction_id: task.cleaning_instruction_id, data: this.$route.params.cleaningDate, lat: latitud, lng: longitud })
                } 

                // Tarea es foto
                if ( task.cleaning_instruction_type == 'photo') {
                    this.$store.dispatch( "cleaning/postNetejesChecklistFoto", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), cleaning_id: this.cleaningDetail.cleaning.cleaning_id, cleaning_instruction_id: task.cleaning_instruction_id, base64: this.taskPhoto, data: this.$route.params.cleaningDate, lat: latitud, lng: longitud })
                    this.taskPhoto = null; 
                }
            }
        },
        filters: {
        toLower(value) {
            if (!value) return "";
            value = value.toString();
            return value.toLowerCase();
        }
    },
    };
</script>


<style lang="scss" scoped>
    $color-verde-check: #70cb63;
    header {
        background: none;

        h1, span {
            color: var(--azul-oscuro);
            font-weight: 700;
            font-size: 1.5rem;
        }
        .flex-titular {
            display: flex;
            justify-content: space-between;
            max-width: 752px;
            margin: auto;

            .titular-fecha {
                font-size: 14px;    
            }
        }
        .link-atras {
            padding: 12px;
            color: #FFF;
            &:hover {
                color: #b5b5b5;
            } 
        }
    }

    .container {
        background-color: #f6f7f9;

        .bg-verde {
            border-color: var(--colores-verde-check);
            background-color: var(--colores-verde-check);
            transition: background-color .3s;
            &:hover {
                background-color: darken($color: $color-verde-check, $amount: 10%);
                transition: background-color .3s;
            }
        }

        article {
            div {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                
                span {
                    padding: 10px 0;
                }
            }
            .titular-card {
                border-bottom: 1px solid var(--colores-gris-medio);
                color: var(--colores-gris-medio);
            }
        }


                .empty-photo {
                    width: 150px;
                    height: 100px;
                    margin: 1.2rem auto .2rem auto;
                    border: 1px solid var(--colores-gris-medio);
                    border-radius: 5px;
                }
                label {
                    font-size: 16px;
                    color: var(--azul-oscuro);

                    .btn-upload-photo {
                        width: 150px !important;
                    }
                }

        form {

            .content-inputs {
                .form-error {

                    p {
                        color: var(--red);
                    }
                }
            }
        }   
    }

    .tareas {
        background-color: #f6f7f9;
        border-radius: 10px;
        margin: 0 2rem;
    }
    .tarea {
        min-height: 65px;
        padding: 0 2rem;
        border-radius: 5px;
        cursor: pointer;
    }

    @media (max-width: 767px) {
        .tareas {
            margin: 0 .5rem;
        }
        .tarea {
            padding: 0 1rem;
        }
    }
    
    
    .cursor-pointer {
        cursor: pointer;
    }
    .tareas p {
        font-size: 16px;
        color: var(--azul-oscuro);
    }
    .task-confirm {
        color: $color-verde-check;
    }
    .task-photo > .img-preview {
        max-height: 41px !important;
        width: auto;
    }
    .task-photo > label {
        line-height: inherit;
        cursor: pointer;
    }
    .task-checked {
        color: $color-verde-check;
        cursor: pointer;
    }
    .empty-task-photo {
        width: 61px;
        height: 41px;
        border: 1px dashed #a2a2a2;
        border-radius: 5px;
        margin-left: auto;
        
    }
    .show-task-photo {
        height: 41px;
        width: auto;
        border-radius: 5px;
        margin-left: auto;
    }
    .text-checked {
        color: #9f9f9f;
        cursor: pointer;
    }

    .remark {
        cursor: initial;
    }
    .fondo-oscuro {
        background-color: #f7f7f7;
        border-radius: 5px;
        position: relative;
    }
</style>