<template>
    <div>
        <!-- Cabecera -->
        <header v-if="translator" class="p-3">
            <div class="flex-titular">
                <div>
                    <h1 v-cloak>{{ translator.neteja }}</h1>
                </div>
                <div class="text-right text-white">

                    <button type="button" class="btn btn-link legend-btn" data-toggle="modal" data-target="#ModalLabelLegend">
                        <i class="fal fa-question-circle fa-lg"></i>
                    </button>


                    <!-- botón para profile aquí -->
                </div>
            </div>
        </header>
        <!-- Fin cabecera -->

        <div class="pc">

            <!-- Spiner -->
            <div v-if="isPreLoad" style="height: 90vh; display:flex; justify-content:center; align-items:center; background-color: #f6f7f9;">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
            <!-- Fin spiner -->

            <NavbarCleaning :translator="translator" :user="user" />

            <div class="container p-4">
                <div v-if="!isPreLoad && cleanings.dates.length < 1">
                    {{ cleanings.dates.length }} {{ translator.neteja }}
                </div>
                <div v-else v-for="(cleaning, i) in cleanings.dates" :key="i">
                    <!-- Fechas -->
                    <div class="fechas-collapse flex-between" data-toggle="collapse" :href="'#collapse'+i" role="button" aria-expanded="true" :aria-controls="'collapse'+i" @click="cleaning.expanded = !cleaning.expanded">
                        <span>{{cleaning.date | TransformElegantDate }}</span>
                        <i v-if="!cleaning.expanded" style="font-size: 18px;" class="arrow fal fa-angle-down"></i>
                        <i v-else style="font-size: 18px;" class="arrow fal fa-angle-up"></i>
                    </div>
                    <div class="collapse show" :id="'collapse' + i">

                        <!-- Información collapse -->
                        <router-link v-for="(cleaningtype, x) in cleaning.cleanings" :key="x"
                                     :to="{ name: 'CleaningDetails', params: {cleaningDate: cleaning.date, bookingId: createID(cleaningtype) }, query: { id: $route.query.id }}"
                                     class="card-body shadow-sm mt-2 flex-between"
                                     :class="statusCleaning(cleaningtype.cleaning.cleaning_status)">
                            <div>
                                <span v-if="cleaningtype.cleaning.cleaning_time != ''">{{ cleaningtype.cleaning.cleaning_time }}h</span>
                                <span v-else-if="cleaningtype.cleaning.cleaning_id == ''">
                                    <span class="badge bg-secondary bg-nocleaning">NO CLEANING</span>
                                </span>
                                <span v-else>--</span>
                                <br>
                                <span>{{ cleaningtype.property.lodging_name }}</span>
                            </div>

                            <div class="check-in-out">

                                <div v-if="cleaningtype.arrival.arrival_guests.split(',').reduce((a, b) => a + parseInt(b, 10), 0) > 0">
                                    <i class="fal fa-users"></i> <span>{{ cleaningtype.arrival.arrival_guests.split(',').reduce((a, b) => a + parseInt(b, 10), 0) }}</span>
                                </div>

                                <!-- Salidas -->
                                <div>
                                    <div v-if="cleaningtype.departure.departure_time != '' || cleaningtype.departure.departure_reservation != ''">
                                        <span>{{ translator.checkout }} </span>
                                        <span v-if="cleaningtype.departure.departure_time != ''">{{ cleaningtype.departure.departure_time }}h</span>
                                    </div>
                                </div>

                                <!-- Entradas -->
                                <div v-if="cleaningtype.arrival.arrival_time != '' || cleaningtype.arrival.arrival_reservation != ''">
                                    <span>{{ translator.checkin }} </span>
                                    <span v-if="cleaningtype.arrival.arrival_time != ''">{{ cleaningtype.arrival.arrival_time }}h</span>
                                </div>

                                <div v-if="cleaningtype.cleaning.cleaning_person != ''">
                                    <i class="fal fa-vacuum fa-sm"></i> <small>{{ cleaningtype.cleaning.cleaning_person }}</small>
                                </div>

                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>


        <!-- Modal -->
        <div class="modal fade legend" id="ModalLabelLegend" tabindex="-1" aria-labelledby="ModalLabelLegend" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{ translator.llegenda_titular }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body modal-body-leyenda">
                        <img class="img-fluid" src="/img/limpiezaLeyenda.webp" alt="Leyenda">

                        <h4>
                            1. {{ translator.llegenda_colors }}:
                        </h4>
                        <p>
                            <span class="rojo">{{ translator.llegenda_vermell }}</span> {{ translator.llegenda_vermell_text }}
                        </p>
                        <p>
                            <span class="amarillo">{{ translator.llegenda_groc }}</span> {{ translator.llegenda_groc_text }}
                        </p>
                        <p>
                            <span class="verde">{{ translator.llegenda_verd }}</span> {{ translator.llegenda_verd_text }}
                        </p>
                        <p>
                            <span class="gris">{{ translator.llegenda_gris }}</span> {{ translator.llegenda_gris_text }}
                        </p>

                        <h4>
                            2. {{ translator.hora }}:
                        </h4>
                        <p>
                            {{ translator.llegenda_hora1 }}
                        </p>
                        <p>
                            {{ translator.llegenda_hora2 }}
                        </p>

                        <h4>
                            3. {{ translator.llegenda_dreta1 }}:
                        </h4>
                        <p>
                            {{ translator.llegenda_dreta2 }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import NavbarCleaning from "@/components/NavbarCleaning.vue";
    import { mapState } from "vuex";

    export default {
        name: "Cleaning",
        components: {
            NavbarCleaning,
        },
        computed: {
            ...mapState("utils", ['user', 'translator']),
            ...mapState("cleaning", ['cleanings']),
        },
        watch: {
            translator() {
                this.$store.dispatch("cleaning/getCleanings", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), data: new Date() });
            },
            cleanings() {
                this.isPreLoad = false;
                sessionStorage.setItem('back', 'Cleaning');
            },
        },
        async created() {
            try {
                this.isPreLoad = true;
                await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
                document.getElementsByTagName('body')[0].style.backgroundColor = '#f6f7f9';
                sessionStorage.removeItem('btm')
            } catch (error) {
                console.error(error);
            } finally {
                //this.isPreLoad = false;
            }
        },
        data() {
            return {
                isPreLoad: false,
            };
        },
        methods: {
            statusCleaning(status) {
                switch (status) {
                    case 'pending':
                        return 'pending';
                    case 'in_progress':
                        return 'inprogress';
                    case 'finished':
                        return 'finished';
                    default:
                        return 'neutral';
                }
            },
            createID(data) {
                if (data.cleaning.cleaning_id != '') {
                    return data.cleaning.cleaning_id;
                } else if (data.arrival.arrival_reservation != '') {
                    return data.arrival.arrival_reservation;
                } else if (data.departure.departure_reservation != '') {
                    return data.departure.departure_reservation;
                } else {
                    return;
                }
            }
        },
        filters: {
            toLower(value) {
                if (!value) return '';
                value = value.toString();
                return value.toLowerCase();
            },
            flipDate(value) {
                if (!value) return ''
                const backendDate = value.split('-')
                return `${backendDate[2]}-${backendDate[1]}-${backendDate[0]}`
            },
            TransformElegantDate(date) {
                if (!date) return ''

                const allMonths = JSON.parse(sessionStorage.getItem("diccionario")).monthShort;
                const allWeekdays = JSON.parse(sessionStorage.getItem("diccionario")).weekdayShort;
                const months = [
                    allMonths[0],
                    allMonths[1],
                    allMonths[2],
                    allMonths[3],
                    allMonths[4],
                    allMonths[5],
                    allMonths[6],
                    allMonths[7],
                    allMonths[8],
                    allMonths[9],
                    allMonths[10],
                    allMonths[11],
                ];
                const week = [
                    allWeekdays[0],
                    allWeekdays[1],
                    allWeekdays[2],
                    allWeekdays[3],
                    allWeekdays[4],
                    allWeekdays[5],
                    allWeekdays[6],
                ];

                let long_date = "";
                let day = date.slice(8, 10);
                let month = date.slice(5, 7);
                let year = date.slice(0, 4);

                let new_date = new Date(date);
                let day_week = new_date.getUTCDay();
                long_date = week[day_week] + ", " + day + " " + months[month - 1] + " " + year;

                return long_date;
            },
        }
    };
</script>

<style scoped>
    header .flex-titular {
        display: flex;
        justify-content: space-between;
        max-width: 752px;
        margin: auto;
    }

    .container {
        background-color: #f6f7f9;
        min-height: calc(100vh - 120px);
        width: 100%;
    }

    .fechas-collapse {        
        font-size: 12px;
        color: var(--colores-gris-medio);
    }

    .bg-nocleaning {
        color: #fff;
        font-size: 11px;
        font-weight: normal;
        padding: 3px 4px;
        margin-bottom: 5px;
    }

    .pending {
        border-left: solid 5px var(--red);
        border-radius: 5px;
    }

    .inprogress {
        border-left: solid 5px var(--colores-amarillo-2);
        border-radius: 5px;
    }

    .finished {
        border-left: solid 5px var(--colores-verde-check);
        border-radius: 5px;
    }

    .neutral {
        border-left: solid 5px var(--colores-gris-medio);
        border-radius: 5px;
    }

    .flex-between {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .card-body {
        background-color: #FFF;
        text-decoration: none;
        color: var(--azul-oscuro);
        transition: box-shadow .25s;
    }

        .card-body:hover {
            transition: box-shadow .2s;
            box-shadow: 0 0.125rem 0.45rem rgba(0,0,0,.2) !important;
        }

        .card-body .check-in-out {
            text-align: right;
            font-size: 12px;
        }


    .legend-btn {
        color: #FFF;
    }

        .legend-btn:hover {
            color: rgb(190, 190, 190)
        }

    .legend .modal-header {
        background: rgb(246, 247, 249);
    }

    .legend .modal-body {
        background: rgb(246, 247, 249);
        border-bottom-right-radius: calc(0.3rem - 1px);
        border-bottom-left-radius: calc(0.3rem - 1px);
    }

    .legend .modal-body-leyenda img {
        width: 100%;
        max-width: 300px;
    }

    .legend h4 {
        font-size: 17px;
        padding-top: 15px;
    }

    .legend p {
        color: inherit;
        margin-bottom: 7px;
    }

    .legend .rojo,
    .legend .amarillo,
    .legend .verde,
    .legend .gris {
        font-weight: bold;
    }

    .legend .rojo {
        color: var(--red);
    }

    .legend .amarillo {
        color: var(--colores-amarillo-2);
    }

    .legend .verde {
        color: var(--colores-verde-check);
    }

    .legend .gris {
        color: var(--colores-gris-medio);
    }
</style>