<template>
  <div>
    <nav id="navbar" class="fixed-bottom shadow nav-fill border-top">
      <ul class="nav">
        <!-- Inicial limpiezas -->
        <router-link :to="{ name: 'Cleaning', query: { id: $route.query.id }}" class="nav-item py-2">
          <i class="fal fa-home"></i>
          <div class="text-opcio">{{ translator.inici }}</div>
        </router-link>

        <!-- Calendari -->
        <router-link :to="{ name: 'CleaningCalendar', query: { id: $route.query.id }}" class="nav-item py-2">
          <i class="fal fa-calendar-alt"></i>
          <div class="text-opcio">{{ translator.calendari }}</div>
        </router-link>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'NavbarCleaning',
  props: ['user', 'translator'],
}
</script>

<style scoped lang="scss">
#navbar {
  a {
    width: 50%;
    min-height: 61px;
    color: var(--colores-gris-medio);

    &.router-link-exact-active {
      color: var(--azul-oscuro);
    }
  }
}
</style>